<template>
  <div style="min-height:60px">
    <div v-show="goodToGo" class="ion-padding">
      <ion-segment v-if="paymentMethodsArray.length > 1 && !partOfPackageLesson" v-model="selectedPaymentMethod">
        <ion-segment-button v-if="paymentMethodsArray.includes('creditCard')" value="creditCard">
          Pay Online
        </ion-segment-button>
        <ion-segment-button v-if="paymentMethodsArray.includes('offline')" value="offline">
          Pay Later
        </ion-segment-button>
      </ion-segment>
      <div v-if="partOfPackageLesson">
        <h4>{{ stripeSession.metadata.name }}</h4>
        <ion-button
          fill="solid" color="success" expand="block"
          class="ion-margin-top" data-testid="offline-payment-book-button" @click="completePayment"
        >
          Book Lesson
        </ion-button>
      </div>
      <div v-if="selectedPaymentMethod == 'offline' && !partOfPackageLesson">
        <div v-if="stripeSession.metadata.paymentMethod != 'offline'">
          <ion-spinner />
        </div>
        <div v-else>
          <h4>{{ stripeSession.metadata.name }}</h4>
          {{ stripeSession.metadata.instructorName }} will handle the payment offline.
          <ion-button
            fill="solid" color="success" expand="block"
            class="ion-margin-top" data-testid="offline-payment-book-button" @click="completePayment"
          >
            <span v-if="mode == 'timeslot'">Book Lesson</span>
            <span v-if="mode == 'package'">Book Package</span>
          </ion-button>
        </div>
      </div>
      <div v-show="selectedPaymentMethod == 'creditCard' && !partOfPackageLesson" id="checkout">
        <!-- Checkout will insert the payment form here -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { IonButton, IonSegment, IonSegmentButton, IonSpinner } from '@ionic/vue'
import { computed, onUnmounted, ref, watch } from 'vue'
import { useScriptTag } from '@vueuse/core'

import { STRIPE_PUBLIC_KEY, STRIPE_TEST_PUBLIC_KEY } from '@/globals'
import { createStripeSession, createStripeSessionForPackage } from '@/firebase'

const props = defineProps({
  goodToGo: { required: true, type: Boolean },
  mode: { required: true, type: String, validator: value => ['timeslot', 'package'].includes(value) },
  timeslot: { required: false, type: Object, default: () => ({}) },
  package: { required: false, type: Object, default: () => ({}) },
  paymentMethods: { required: true, type: Object, default: () => ({ creditCard: true, offline: false }) },
  demoMode: { required: false, type: Boolean, default: false },
})
const emit = defineEmits(['payment-complete'])

const stripeSession = ref({ metadata: {}})
let checkout = null
// select only enabled payment methods
const paymentMethodsArray = Object.keys(props.paymentMethods).filter(key => props.paymentMethods[key]).sort()
const selectedPaymentMethod = ref(paymentMethodsArray[0])

const partOfPackageLesson = computed(() => props.mode === 'timeslot' && stripeSession.value?.metadata?.packageId && stripeSession.value?.metadata?.pricingPrice === 0)

watch(selectedPaymentMethod, async () => {
  initializeCheckout(selectedPaymentMethod.value)
})

onUnmounted(() => {
  checkout?.destroy()
})

function completePayment() {
  // strip clientSecret from stripeSession
  const paymentInfo = { ...stripeSession.value }
  delete paymentInfo.clientSecret

  emit('payment-complete', paymentInfo)
  checkout.destroy()
  checkout = null
}

async function initializeCheckout(paymentMethod) {
  checkout?.destroy()
  const stripe = Stripe(props.demoMode ? STRIPE_TEST_PUBLIC_KEY : STRIPE_PUBLIC_KEY)

  // Initialize Checkout
  checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret: async () => {
      if (paymentMethod !== selectedPaymentMethod.value) return // ignore if the payment method has changed and prevent async race condition
      if (props.mode === 'timeslot') {
        stripeSession.value = (await createStripeSession({ timeslot: props.timeslot, paymentMethod: selectedPaymentMethod.value })).data
      } else if (props.mode === 'package') {
        stripeSession.value = (await createStripeSessionForPackage({ pckg: props.package, paymentMethod: selectedPaymentMethod.value })).data
      }
      return stripeSession.value.clientSecret
    },
    onComplete: completePayment,
  })
  // Mount Checkout
  checkout.mount('#checkout')
}

useScriptTag('https://js.stripe.com/v3/', () => initializeCheckout(selectedPaymentMethod.value))

</script>
