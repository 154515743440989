<template>
  <ion-item
    :button="true" :detail="false" lines="full"
    class="ion-no-padding instructor-item" data-testid="instructor-card"
  >
    <div class="instructor-grid">
      <div class="avatar-column">
        <user-avatar :url="instructor.photoURL" :router-link="instructorLink" />
      </div>
      <div class="content-column" @click="$router.push(instructorLink)">
        <h4>{{ instructor.name }}</h4>
        <ion-note class="golf-course single-line">{{ golfCourses[0] }}</ion-note>
        <p class="ion-no-margin">{{ prices.join(', ') }}</p>
        <div v-if="instructor.specializations?.length" class="specializations single-line">
          "{{ instructor.specializations?.trim() }}"
        </div>
      </div>
      <div class="icon-column">
        <ion-icon
          v-if="currentUser"
          data-testid="instructor-like-button"
          :icon="props.liked ? heart : heartOutline"
          color="danger" size="large" @click="like(instructor.id)"
        />
      </div>
    </div>
  </ion-item>
</template>

<script setup>
import { IonIcon, IonItem, IonNote } from '@ionic/vue'
import { heart, heartOutline } from 'ionicons/icons'
import { computed } from 'vue'
import { useCurrentUser } from 'vuefire'

import UserAvatar from '@/3_widgets/UserAvatar.vue'

const props = defineProps({
  instructor: { required: true, type: Object },
  filter: { default: () => { return {} }, type: Object },
  liked: { required: true, type: Boolean, default: false },
})
const emit = defineEmits(['like:instructor'])

const currentUser = useCurrentUser()
const instructor = props.instructor
const instructorLink = computed(() => {
  return {
    name: (currentUser.value ? 'instructor' : 'guestInstructor'),
    params: { instructorId: props.instructor.id },
  }
})

const golfCourses = computed(() => {
  let result = []
  instructor.instructor.lessonTypes.forEach((lessonType) => {
    const pricingType = lessonType.pricing[props.filter.pricingType]
    if (pricingType) {
      result.push(lessonType.location.name)
    }
  })
  result = [...new Set(result)]
  return result
})

const prices = computed(() => {
  const result = []
  instructor.instructor.lessonTypes.forEach((lessonType) => {
    const pricingType = lessonType.pricing[props.filter.pricingType]
    if (pricingType) {
      result.push(`$${pricingType.price} / ${lessonType.length}min`)
    }
  })
  return result
})

function like(userId) {
  emit('like:instructor', userId)
}

</script>

<style scoped>
.instructor-item {
  --padding-start: 1em;
  --inner-padding-end: 0.5em;
  --padding-top: 0.75em;
  --padding-bottom: 0.75em;
}
.instructor-grid {
  display: grid;
  grid-template-columns: 70px 1fr 44px;
  width: 100%;
  align-items: center;
}
.avatar-column {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-column {
  padding: 0 10px;
  overflow: hidden;
}
.icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
h4 {
  margin-top: 0;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.golf-course {
  display: block;
  margin-bottom: 4px;
}
h6 {
  margin-top: 0;
  margin-bottom: 4px;
}
.specializations {
  font-style: italic;
  color: #666;
  margin-top: 1em;
}

</style>
