<template>
  <ion-page v-if="lesson" data-testid="lesson-edit-page">
    <ion-header>
      <ion-toolbar>
        <ion-title data-testid="lesson-status">
          Reschedule Lesson
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessonPage', params: {lessonId: lesson.id}}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" fullscreen="true">
      <!-- <pre>{{ changes }}</pre> -->
      <!-- <pre>{{ v$ }}</pre> -->
      <ion-card>
        <ion-card-content>
          <ion-list data-testid="changes-timeslot" class="ion-no-padding">
            <ion-item lines="none" style="text-decoration: line-through;">
              <ion-label>Current:</ion-label>
              <strong>{{ lesson.formattedDate }}, {{ formatTime(lesson.time) }}</strong>
            </ion-item>
            <ion-item lines="none">
              <ion-label>New:</ion-label>
              <p v-if="changes.newTimeslot" class="ion-text-end">
                <strong>{{ changes.newTimeslot.formattedDate }}, {{ formatTime(changes.newTimeslot.formattedTime) }}</strong>
                <br>
                <a @click="changes.newTimeslot = null">change time</a>
              </p>
            </ion-item>
          </ion-list>
          <timeslots-calendar
            v-if="instructor && !changes.newTimeslot" :instructor="instructor"
            :selected-location="lesson.location" :selected-pricing-type="lesson.pricing.key"
            :current-user="userStore.is.type" @timeslot:selected="changes.newTimeslot = $event"
          />
        </ion-card-content>
      </ion-card>
      <!-- @timeslot:selected="selectedTimeslot = $event; timeslotModalRef.$el.present()" -->
      <ion-toolbar>
        <ion-text v-if="v$.$errors.length" color="danger">
          <ul data-testid="error-messages">
            <li v-for="err in v$.$errors" :key="err.$uid">{{ err.$message }}</li>
          </ul>
        </ion-text>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button
                id="request-changes-trigger" :disabled="v$.$invalid" expand="block"
                color="success" fill="solid" @click="initRequestChanges"
              >
                <ion-icon slot="start" :icon="swapHorizontalOutline" />
                Request Changes
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
  IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem,
  IonLabel, IonList, IonPage, IonRow, IonText, IonTitle, IonToolbar, alertController,
} from '@ionic/vue'
import { computed, reactive } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { swapHorizontalOutline } from 'ionicons/icons'
import { useDocument } from 'vuefire'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'

import { LessonsRepository } from '@/data/LessonsRepository'
import TimeslotsCalendar from '@/views/golfers/lessons/TimeslotsCalendar.vue'
import { formatTime } from '@/6_shared/utils/formattingLessons'
import { instructorRef } from '@/state'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const router = useRouter()
const userStore = useUserStore()

const lessonsRepo = new LessonsRepository()

const props = defineProps({
  lessonId: { type: String, required: true },
})

const lesson = lessonsRepo.get(props.lessonId)
const localRef = computed(() => instructorRef(lesson.value?.instructorId))
const instructor = useDocument(localRef)

const changes = reactive({ newTimeslot: null })

const rules = {
  newTimeslot: {
    required: helpers.withMessage('Please select a new timeslot', required),
  },
}
const v$ = useVuelidate(rules, changes)
v$.value.$touch()

const rescheduleAlertButtons = [{
  text: 'Cancel',
  role: 'cancel',
}, {
  text: 'Send Request',
  role: 'confirm',
  handler: async () => {
    const timeslot = changes.newTimeslot
    lesson.value.changes = {
      old: {
        datetime: lesson.value.datetime,
        date: lesson.value.date, // "2024-05-26"
        time: lesson.value.time, // "09:00"
        formattedDate: lesson.value.formattedDate, // "Sun May 26"
      },
      new: {
        datetime: timeslot.datetime,
        date: timeslot.date, // "2024-05-26"
        time: timeslot.formattedTime, // "09:00"
        formattedDate: timeslot.formattedDate, // "Sun May 26"
        datetimeIso: timeslot.datetimeIso,
      },
      requestor: {
        name: userStore.userData().name,
        id: userStore.userData().id,
        type: userStore.is.type,
        requestedAt: new Date(),
      },
      approvals: {
        student: {
          approved: userStore.is.student ? true : null,
          createdAt: new Date(),
        },
        instructor: {
          approved: userStore.is.instructor ? true : null, // initiated by instructor
          createdAt: null,
        },
      },
    }
    await sendRequest('Sending Reschedule Request...', async () => {
      await lessonsRepo.requestReschedule(lesson.value)
      router.push({ name: 'lessonPage', params: { lessonId: lesson.value.id }})
    }, lesson.value)
  },
}]

async function initRequestChanges() {
  const alert = await alertController.create({
    header: 'Reschedule Lesson',
    message: 'Send reschedule request to Instructor. If accepted, the lesson will be rescheduled. If declined, you will be notified.',
    buttons: rescheduleAlertButtons,
  })
  await alert.present()
}
</script>
