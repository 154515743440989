<template>
  <ion-badge
    v-if="count" color="danger" data-testid="pending-lessons-count"
    class="animate__animated animate__swing"
  >
    {{ count }}
  </ion-badge>
</template>

<script setup>
import { IonBadge } from '@ionic/vue'
import { computed } from 'vue'

import { LessonsRepository } from '@/data/LessonsRepository'
import { groupBy } from 'lodash'

const lessonsRepo = new LessonsRepository()
const lessons = lessonsRepo.getUserLessons()
const count = computed(() => {
  const result = groupBy(lessons.value, lesson => lesson.stateGroup())['pending'] || []
  return result.length > 0 ? result.length : ''
})
</script>
