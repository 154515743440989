<template>
  <div v-if="me || userStore.is.demoStudent">
    <ion-card style="margin-top:3em">
      <ion-card-header>
        <ion-card-title>Subscribe to Your Lessons Calendar</ion-card-title>
      </ion-card-header>
      <ion-card-content class="ion-text-center">
        <div v-if="currentCalendarUrl">
          <ion-button
            size="small" fill="outline" color="medium"
            expand="block" @click="subscribeToCalendar('google')"
          >
            <ion-icon :icon="logoGoogle" />
            &nbsp;Google Calendar
          </ion-button>
          <ion-button
            size="small" fill="outline" color="medium"
            expand="block" @click="subscribeToCalendar('apple')"
          >
            <ion-icon :icon="logoApple" />
            &nbsp;Apple Calendar
          </ion-button>
          <ion-button
            size="small" fill="outline" color="medium"
            expand="block" @click="subscribeToCalendar('outlook')"
          >
            <ion-icon :icon="logoMicrosoft" />
            &nbsp;Microsoft Outlook
          </ion-button>
          <ion-button
            size="small" fill="outline" color="medium"
            expand="block" :data-test-url="currentCalendarUrl"
            @click="subscribeToCalendar('other')"
          >
            <ion-icon :icon="calendarOutline" />
            &nbsp;Other Calendar Apps
          </ion-button>
        </div>
        <ion-spinner v-if="isLoading" />
        <br>
        <ion-button
          v-if="!isLoading" color="danger" fill="outline"
          size="small" @click="createUrl"
        >
          <span v-if="!currentCalendarUrl">Create Lessons Calendar URL</span>
          <span v-if="currentCalendarUrl">Regenerate Calendar URL</span>
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script setup>
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonSpinner } from '@ionic/vue'
import { computed, ref } from 'vue'

import { calendarOutline, logoApple, logoGoogle, logoMicrosoft } from 'ionicons/icons'
import { SM } from '@/globals'
import { createCalendarUrl } from '@/firebase'
import { useUserStore } from '@/stores/user'

const me = computed(() => {
  if (SM.env === 'development') return true
  if (userStore.userData().email.includes('ildar')) return true
  return false
})

const FUNCTION_URL = SM.env === 'development'
  ? 'http://127.0.0.1:5001/swingmatch-8a936/us-west1/generateCalendarFeed'
  : 'https://generateCalendarFeed-aque4yeqwa-uw.a.run.app/generateCalendarFeed'

const userStore = useUserStore()

const currentCalendarUrl = computed(() => {
  if (userStore.userData().calendarFeedUuid) {
    return `${FUNCTION_URL}?id=${userStore.userData().calendarFeedUuid}`
  } else {
    return ''
  }
})

const isLoading = ref(false)
async function createUrl() {
  if (currentCalendarUrl.value) {
    if (!window.confirm('Are you sure you want to reset the Calendar Feed URL? All existing subscriptions will stop working.')) return
  }
  isLoading.value = true
  const response = await createCalendarUrl()
  isLoading.value = false
  alert('Done. Your new Calendar Feed URL is generated.')
  console.log(response)
}

function subscribeToCalendar(type) {
  let encodedUrl = encodeURIComponent(currentCalendarUrl.value)
  let subscribeUrl = ''

  switch (type) {
    case 'google':
      // google doesn't support https protocol for calendars, replace with http
      encodedUrl = encodedUrl.replace('https', 'http')
      subscribeUrl = `https://calendar.google.com/calendar/r?cid=${encodedUrl}`
      break
    case 'apple':
      subscribeUrl = `webcal://${currentCalendarUrl.value.replace('https://', '')}`
      break
    case 'outlook':
      subscribeUrl = `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addsubscription&url=${encodedUrl}&name=SwingMatch%20Calendar`
      break
    case 'other':
      subscribeUrl = currentCalendarUrl.value
      break
  }

  window.open(subscribeUrl, '_blank')
}
</script>
