<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Lesson Invite
        </ion-title>
        <ion-buttons slot="start">
          <ion-back-button :default-href="{name: 'lessons'}" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding" data-testid="lesson-invite-page">
      <template v-if="!isLoading && invite">
        <ion-list>
          <ion-item
            lines="full" data-testid="selected-lesson-type"
            detail="true" @click="chooseLessonTypeModalRef.$el.present()"
          >
            <ion-label v-if="invite.lessonType">
              <strong>{{ invite.lessonType.location.name }}</strong>
              <br>
              <ion-note>{{ invite.pricing.name }} - {{ invite.lessonType.length }} min</ion-note>
            </ion-label>
            <ion-modal
              id="choose-lesson-type-modal" ref="chooseLessonTypeModalRef" data-testid="choose-lesson-type-modal"
            >
              <ion-list>
                <template v-for="(lessonTypes, locationName) of groupedAvailableLessonTypes" :key="locationName">
                  <ion-item><ion-label><strong>{{ locationName }}</strong></ion-label></ion-item>
                  <ion-item
                    v-for="lessonType in lessonTypes" :key="lessonType" lines="none"
                    detail="true" @click="selectLessonType(lessonType)"
                  >
                    <ion-label>{{ lessonType.pricing.name }} - ${{ lessonType.pricing.price }} - {{ lessonType.lessonType.length }} min</ion-label>
                  </ion-item>
                </template>
              </ion-list>
            </ion-modal>
          </ion-item>
          <ion-item
            lines="full" detail="true" data-testid="select-timeslot-trigger"
            @click="timeslotCalendarModalRef.$el.present()"
          >
            <ion-label>Date & Time:</ion-label>
            <ion-badge v-if="v$.date.$invalid" slot="end" color="danger">nothing selected</ion-badge>
            <strong v-if="!v$.date.$invalid">
              {{ invite.formattedDate }}, {{ displayFullLength(invite.formattedTime, invite.lessonType.length) }}
            </strong>
          </ion-item>
          <ion-modal
            id="timeslots-calendar-modal" ref="timeslotCalendarModalRef" data-testid="timeslots-calendar-modal"
          >
            <div style="max-height: 90%; overflow: scroll">
              <timeslots-calendar
                v-if="invite.lessonType"
                :instructor="instructor"
                :selected-location="invite.lessonType.location" :selected-pricing-type="invite.pricing.key"
                :current-user="'instructor'"
                @timeslot:selected="selectTimeslot"
              />
            </div>
          </ion-modal>
          <ion-item
            v-if="invite?.pricing" data-testid="custom-price-block" lines="none"
            style="overflow:visible" class="ion-margin-bottom ion-margin-top"
          >
            <ion-input
              v-model="invite.pricing.price" type="number" mode="md"
              fill="outline" label="Price Override" label-placement="stacked"
              debounce="200" :class="{'ion-invalid ion-touched': v$.pricing.$invalid && v$.pricing.$dirty}"
            >
              <span slot="start">$</span>
            </ion-input>
          </ion-item>
          <ion-item lines="none" data-testid="custom-description-block" style="overflow:visible">
            <ion-textarea
              v-model="invite.description" label="Description"
              label-placement="stacked" rows="3" :auto-grow="true"
              mode="md" fill="outline" debounce="200"
            />
          </ion-item>
          <ion-item v-if="v$.$errors.length" lines="none">
            <ion-text color="danger">
              <ul data-testid="error-messages">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <li v-for="err in v$.$errors" :key="err.$uid" v-html="err.$message" />
              </ul>
            </ion-text>
          </ion-item>
        </ion-list>

        <template v-if="!v$.$invalid">
          <div data-testid="invite-link-block">
            <ion-textarea
              readonly="true" :value="currentUrl" label="Invite Link"
              label-placement="stacked" rows="2" mode="md"
              fill="outline" class="ion-margin-top"
            />
            <ion-button
              color="success" expand="block" fill="outline"
              @click="copyToClipboard"
            >
              Copy to Clipboard
            </ion-button>
            <ion-note class="">
              <ul>
                <li>You can send this link to a student to invite them to join your lesson</li>
                <li>The link can be shared via Chat, Email or Text Message</li>
                <li>The link can be used only once and does not expire</li>
              </ul>
            </ion-note>
          </div>
        </template>
        <!-- <ion-button
          color="medium" fill="outline" expand="block"
          @click="deleteInvite"
        >
          Cancel Invite
        </ion-button> -->
      <!-- <pre>invite: {{ invite }}</pre> -->
      </template>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader,
  IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonText, IonTextarea, IonTitle, IonToolbar, onIonViewWillLeave, toastController,
} from '@ionic/vue'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useCurrentUser, useDocument } from 'vuefire'
import { useRoute, useRouter } from 'vue-router'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import { ALL_PRICING_TYPES } from '@/data/Instructor.schema'
import { InstructorsConverter } from '@/5_entities/instructors/Instructor'
import { LessonInvitesRepository } from '@/data/LessonInvite'
import TimeslotsCalendar from '@/views/golfers/lessons/TimeslotsCalendar.vue'
import { displayFullLength } from '@/6_shared/utils/formattingLessons.js'
import { instructorRef } from '@/state'
import { useUserStore } from '@/stores/user'

const currentUser = useCurrentUser()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const lessonInviteRepo = new LessonInvitesRepository()
const isLoading = ref(false)
const instructor = useDocument(instructorRef(currentUser.value.uid).withConverter(InstructorsConverter))
const timeslotCalendarModalRef = ref()
const invite = ref(null)

const rules = {
  date: {
    required: helpers.withMessage('Please select Date & Time', value => !!value),
  },
  pricing: {
    required: helpers.withMessage('Please enter price', pricing => !!pricing.price),
  },
}

const v$ = useVuelidate(rules, invite)
v$.value.$touch()

onMounted(async () => {
  const inviteId = route.params.inviteId
  useDocument(lessonInviteRepo.getRef(inviteId), { target: invite })

  if (userStore.userData().type === 'student') {
    // If user is logged in as student, redirect to home page with inviteId
    router.push({ name: 'insideHome', query: { inviteId: inviteId }})
  }

  watchEffect(async () => {
    if (invite.value && !v$.value.$invalid) {
      invite.value.inviteId = invite.value.id
      lessonInviteRepo.update(invite.value)
    }
  })

  watchEffect(() => {
    if (invite.value && !invite.value.lessonType && availableLessonTypes.value.length) {
      selectLessonType(availableLessonTypes.value[0])
    }
  })
})

const availableLessonTypes = computed(() => {
  let result = []
  if (!instructor.value?.instructor?.lessonTypes) return result
  instructor.value.instructor.lessonTypes.forEach((lessonType) => {
    for (const [_key, value] of Object.entries(lessonType.pricing)) {
      result.push({ pricing: value, lessonType })
    }
  })
  const pricingTypesOrder = Object.keys(ALL_PRICING_TYPES)
  result = result.sort((a, b) => pricingTypesOrder.indexOf(a.pricing.key) - pricingTypesOrder.indexOf(b.pricing.key))
  return result
})

const groupedAvailableLessonTypes = computed(() => {
  const grouped = {}
  availableLessonTypes.value.forEach((lessonType) => {
    const groupName = lessonType.lessonType.location.name
    grouped[groupName] ||= []
    grouped[groupName].push(lessonType)
  })
  return grouped
})

const chooseLessonTypeModalRef = ref()
async function selectLessonType(lessonType) {
  invite.value.lessonType = lessonType.lessonType
  invite.value.pricing = lessonType.pricing // override pricing
  invite.value.description = lessonType.lessonType.description // override description
  chooseLessonTypeModalRef.value?.$el?.dismiss()
}

async function selectTimeslot(timeslot) {
  invite.value.userId = currentUser.value.uid
  invite.value.instructor = {
    name: userStore.userData().name,
    photoURL: userStore.userData().photoURL,
  }
  delete timeslot.pricing // don't override pricing with default timeslot pricing
  Object.assign(invite.value, timeslot)
  timeslotCalendarModalRef.value.$el.dismiss()
}

const currentUrl = computed(() => location.href)
async function copyToClipboard() {
  navigator.clipboard?.writeText(location.href)
  const toast = await toastController.create({
    message: 'Invite link copied to clipboard!',
    color: 'success', duration: 1500, position: 'bottom',
  })
  await toast.present()
}

// const router = useRouter()
// async function deleteInvite() {
//   await lessonInviteRepo.delete(invite.value)
//   router.replace({ name: 'lessons' })
// }

onIonViewWillLeave(() => {
  timeslotCalendarModalRef.value?.$el?.dismiss()
})
</script>

<style>
/* CAN NOT BE SCOPED */
ion-modal#choose-lesson-type-modal {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 14px;
}

/* CAN NOT BE SCOPED */
ion-modal#timeslots-calendar-modal {
  --width: 90%;
  --min-width: 250px;
  --max-width: 90%;
  --height: fit-content;
  --max-height: 80%;
  --overflow: scroll;
  --border-radius: 14px;
}
</style>
