<template>
  <ion-accordion-group data-testid="payment-info" :value="isOpen ? 'payment-info' : null">
    <ion-accordion value="payment-info">
      <ion-item slot="header" color="light">
        Payment Info
      </ion-item>
      <div v-if="stripeSession" slot="content" class="ion-padding">
        <a
          v-if="currentUser === 'student' && receiptUrl" class="ion-float-right" :href="receiptUrl"
          target="_blank" data-testid="receipt-link"
        >
          <ion-icon :icon="receiptOutline" size="large" />
        </a>
        <strong>
          {{ amountToDisplay }} - {{ lesson.paymentStatus() }}
        </strong>
        <br>
        Created: {{ formatDate(stripeSession.created * 1000, 'MMM d, y h:mm a') }}.
        <p v-if="stripeSession.payment_intent?.canceled_at">
          Cancelled: {{ formatDate(stripeSession.payment_intent.canceled_at * 1000, 'MMM d, y h:mm a') }}
          <br>
          Authorized amount was returned.
        </p>
        <p v-if="stripeSession.payment_intent && lesson.paymentInfo?.status == 'authorized'">
          Authorization expires: <strong>{{ authorizationExpires }}</strong>. Instructor will capture payment upon lesson confirmation.
        </p>
        <!-- TODO: check how it looks with actual refunds -->
        <p v-if="refundAmount && currentUser === 'student'">Refunded: ${{ refundAmount }}</p>
        <template v-if="lesson.isConfirmedByInstructor() && !lesson.partOfPackage()">
          <template v-if="lesson.needToCollectPayment() && currentUser === 'instructor'">
            <ion-button expand="block" @click="markAsPaid">Mark as Paid</ion-button>
            <ion-button expand="block" fill="outline" @click="sendPaymentReminder">Send Payment Reminder to Golfer</ion-button>
          </template>
          <ion-button
            v-if="lesson.paymentCollected() && currentUser === 'instructor'" size="small" expand="block"
            fill="clear" color="danger" @click="markAsUnpaid"
          >
            Mark as Unpaid
          </ion-button>
        </template>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup>
import {
  IonAccordion, IonAccordionGroup, IonButton, IonIcon, IonItem, toastController,
} from '@ionic/vue'
import { formatDate, formatDistanceToNow } from 'date-fns'
import { computed } from 'vue'
import { db } from '@/firebase'
import { doc } from 'firebase/firestore'
import { receiptOutline } from 'ionicons/icons'
import { useDocument } from 'vuefire'

import { SM } from '@/globals'
import { sendRequest } from '@/6_shared/utils/sendRequest'

const props = defineProps({
  lesson: { required: true, type: Object },
  currentUser: { type: String, required: true },
})

const stripeSessionRef = doc(db, `paymentSessions/${props.lesson.paymentInfo.sessionId}/public/data`)
const { data: stripeSessionDoc } = useDocument(stripeSessionRef)

const stripeSession = computed(() => stripeSessionDoc.value)
const metadata = computed(() => props.lesson.paymentInfo.metadata || {})

const authorizationExpires = computed(() => {
  const captureBefore = stripeSession.value
    .payment_intent
    ?.latest_charge
    ?.payment_method_details
    ?.card
    ?.capture_before
  if (!captureBefore) return
  return formatDistanceToNow(captureBefore * 1000, { addSuffix: true })
})

const receiptUrl = computed(() => stripeSession.value?.payment_intent?.latest_charge?.receipt_url)

const refundAmount = computed(() => {
  const refundAmount = stripeSession.value?.payment_intent?.latest_charge?.amount_refunded
  if (!refundAmount) return
  return (refundAmount / 100).toFixed(2)
})

const amountToDisplay = computed(() => {
  if (!stripeSession.value) return
  if (!metadata.value.paymentMethod || metadata.value.paymentMethod === 'creditCard') {
    if (props.currentUser === 'student') {
      return `$${(stripeSession.value.amount_total / 100).toFixed(2)}`
    } else if (props.currentUser === 'instructor') {
      return `Earnings: $${(stripeSession.value.amount_total / (1 + SM.bookingFeePercent) * SM.takeHomePercent / 100).toFixed(2)}`
    }
  }
  if (metadata.value.paymentMethod === 'offline') {
    return `$${metadata.value.totalPayment}`
  }
  return ''
})

import { LessonsRepository } from '@/data/LessonsRepository'
const LessonsRepo = new LessonsRepository()

async function markAsPaid() {
  await sendRequest('Updating Lesson...', async () => {
    await LessonsRepo.updateOfflinePaymentStatus({ lesson: props.lesson, paymentStatus: 'paid' })
  }, props.lesson)
}

async function markAsUnpaid() {
  await sendRequest('Updating Lesson...', async () => {
    await LessonsRepo.updateOfflinePaymentStatus({ lesson: props.lesson, paymentStatus: 'authorized' })
  }, props.lesson)
}

async function sendPaymentReminder() {
  await sendRequest('Sending Payment Reminder...', async () => {
    await LessonsRepo.sendPaymentReminder({ lesson: props.lesson })
  }, props.lesson)
  const toast = await toastController.create({
    message: 'Payment Reminder Sent',
    duration: 1000,
    color: 'success',
  })
  await toast.present()
}

const isOpen = computed(() => props.currentUser === 'instructor' && props.lesson.offlinePayment())

</script>
