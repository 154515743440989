<template>
  <template v-if="timeslotsLoading">
    <ion-spinner /> loading timeslots...
  </template>
  <template v-if="timeslotsWithLocations && timeslotsWithLocations.timeslots.length == 0 && currentUser !== 'instructor'">
    <error-message
      title="Instructor schedule is not public"
      content="Send them a message for availability via Chat"
    />
  </template>
  <template v-else>
    <ion-datetime
      v-if="timeslotsWithLocations"
      ref="timeslotsCalendarRef" v-model="selectedDate"
      presentation="date" multiple="false" size="cover"
      minute-values="0,15,30,45" :is-date-enabled="isDateEnabled" data-testid="timeslots-calendar"
      :highlighted-dates="isDateHighlighted"
    />
  </template>
  <ion-list v-if="selectedDate">
    <ion-radio-group v-if="currentUser == 'instructor'" v-model="mode">
      <ion-item lines="none">
        <ion-radio
          label-placement="end" justify="start" value="timeslot"
          :disabled="!timeslotsGrouped[selectedDate.split('T')[0]]"
        >
          <span v-if="!timeslotsGrouped[selectedDate.split('T')[0]]">No Timeslots Available</span>
          <span v-else>Select Existing Timeslot</span>
        </ion-radio><br>
      </ion-item>
      <ion-item lines="none">
        <ion-radio label-placement="end" justify="start" value="custom">
          Custom Time:
        </ion-radio>
        <ion-button
          slot="end" fill="outline" color="success"
          size="small" @click="showTimePicker"
        >
          Select Time
        </ion-button>
      </ion-item>
    </ion-radio-group>
    <template v-if="mode == 'timeslot'">
      <ion-item v-if="timeslotsGrouped[selectedDate.split('T')[0]]" lines="none">
        <ion-label>Select Timeslot:</ion-label>
      </ion-item>
      <ion-item
        v-for="timeslot in timeslotsGrouped[selectedDate.split('T')[0]]" :key="timeslot.id" :button="true"
        lines="none" detail="true" data-testid="available-timeslot"
        @click="selectTimeslot(timeslot)"
      >
        <ion-label>
          <ion-icon v-if="timeslot.mode == 'weekly'" :icon="repeatOutline" />
          <ion-icon v-if="timeslot.mode == 'dates'" :icon="calendarClearOutline" />
          {{ displayFullLength(timeslot.formattedTime, timeslot.length) }}
          <br>
          <ion-note v-if="selectedLocation == 'all' && timeslotsWithLocations.locations.length > 1">{{ timeslot.location.name }}</ion-note>
        </ion-label>
      </ion-item>
    </template>
  </ion-list>
</template>

<script setup>
import {
  IonButton, IonDatetime, IonIcon, IonItem, IonLabel, IonList,
  IonNote, IonRadio, IonRadioGroup, IonSpinner,
} from '@ionic/vue'
import { calendarClearOutline, repeatOutline } from 'ionicons/icons'
import { computed, reactive, ref, watch } from 'vue'

import { formatDate, parseISO } from 'date-fns'
import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import { TimeslotsRepository } from '@/data/Timeslot.js'
import { computedAsync } from '@vueuse/core'
import { displayFullLength } from '@/6_shared/utils/formattingLessons.js'
import { showTimePickerActionSheet } from '@/4_features/TimePicker'

const props = defineProps({
  instructor: { required: true, type: Object },
  selectedLocation: { required: true, type: [Object, String] },
  selectedPricingType: { required: true, type: String },
  currentUser: { required: true, type: String, default: 'guest' },
})

const emit = defineEmits(['timeslot:selected', 'update:locations'])

defineModel('locations', {
  type: Array,
  default: () => [],
})

const mode = ref('timeslot')
const customTime = ref('09:00')

const customDateTimeslot = computed(() => {
  const datePart = formatDate(parseISO(selectedDate.value), 'yyyy-MM-dd')
  const timePart = `${customTime.value}:00`
  const combinedIsoString = `${datePart}T${timePart}Z`
  const combinedDate = parseISO(combinedIsoString)

  const result = {
    datetime: combinedDate.getTime(),
    datetimeIso: combinedIsoString,
    date: datePart,
    formattedTime: customTime.value,
    formattedDate: formatDate(parseISO(selectedDate.value), 'EEE MMM dd'),
  }
  return result
})

const selectedDate = ref(null)
const timeslotsCalendarRef = ref()

watch(() => props.selectedLocation, () => {
  timeslotsCalendarRef.value?.$el?.reset() // Reset the calendar to show the new timeslots otherwise the days are not rerendered
  selectedDate.value = null
})

const TimeslotsRepo = new TimeslotsRepository()

const timeslotsLoading = ref(false)
const timeslotsWithLocations = computedAsync(
  async () => {
    const response = await TimeslotsRepo.getTimeslots(props.instructor.id, props.selectedPricingType)
    emit('update:locations', response.data.locations)
    // Resetting the calendar to force a re-render
    timeslotsCalendarRef.value?.$el?.reset()
    return reactive(response.data)
  },
  // initial state
  null,
  { lazy: true, evaluating: timeslotsLoading },
)

const filteredTimeSlots = computed(() => {
  if (timeslotsLoading.value || !timeslotsWithLocations.value) return []
  if (props.selectedLocation === 'all') {
    return timeslotsWithLocations.value.timeslots
  }
  return timeslotsWithLocations.value.timeslots.filter(timeslot => timeslot.location.name === props.selectedLocation.name)
})

const timeslotsGrouped = computed(() => {
  const grouped = {}
  filteredTimeSlots.value.forEach((timeslot) => {
    const groupName = timeslot.date
    grouped[groupName] ||= []
    grouped[groupName].push(timeslot)
  })
  return grouped
})

function isDateEnabled(dateIsoString) {
  if (props.currentUser === 'instructor') return true
  if (!timeslotsGrouped.value) return
  return Object.values(timeslotsGrouped.value)
    .flat().map(slot => slot.date)
    .includes(dateIsoString)
}

function isDateHighlighted(dateIsoString) {
  if (props.currentUser !== 'instructor') return false
  if (!timeslotsGrouped.value) return
  const datesWithTimeslots = Object.values(timeslotsGrouped.value).flat().map(slot => slot.date)
  if (datesWithTimeslots.includes(dateIsoString)) {
    return {
      textColor: 'rgb(0, 128, 0)',
      backgroundColor: '#f0f0f0',
    }
  }
}

function selectTimeslot(timeslot) {
  emit('timeslot:selected', timeslot)
}

async function showTimePicker() {
  await showTimePickerActionSheet((time) => {
    customTime.value = time
    selectCustomDateTimeslot()
  })
}

function selectCustomDateTimeslot() {
  emit('timeslot:selected', customDateTimeslot.value)
}

</script>

<style scoped>
ion-datetime::part(calendar-day), .calendar-day-wrapper {
  /* height: 20px; */
  font-weight: bold;
}

ion-datetime::part(calendar-day disabled) {
  opacity: 0.2;
  font-weight: normal;
}
</style>
