import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { getCurrentUser } from 'vuefire'

import AccountPage from '@/views/AccountPage.vue'
import BookLessonPage from '@/views/golfers/BookLessonPage.vue'
import ChatsIndex from '@/2_pages/chat/ChatsIndex.vue'
import ChatsShow from '@/2_pages/chat/ChatsShow.vue'
import EditLessonPage from '@/views/golfers/EditLessonPage.vue'
import GuestInvitePage from '@/2_pages/guests/InviteLessonPage.vue'
import GuestPage from '@/views/GuestPage.vue'
import HomePage from '@/views/HomePage.vue'
import InstructorPage from '@/views/golfers/InstructorPage.vue'
import InvitePage from '@/2_pages/instructors/InviteLessonPage.vue'
import LessonsPage from '@/views/golfers/LessonsPage.vue'
import NewLessonPage from '@/views/golfers/NewLessonPage.vue'
import SchedulesPage from '@/views/instructors/SchedulesPage.vue'
import ShowLessonPage from '@/views/golfers/ShowLessonPage.vue'

import GuestTabsLayout from '@/2_pages/guests/TabsLayout.vue'
import InsideTabsPage from '@/views/InsideTabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/guest/home',
    name: 'index',
  },
  {
    path: '/guest/',
    component: GuestTabsLayout,
    meta: { requiresGuest: true },
    children: [
      {
        path: '',
        redirect: '/guest/home',
      },
      {
        name: 'guestHome',
        path: 'home',
        meta: { title: 'Welcome to SwingMatch!' },
        component: GuestPage,
      },
      {
        name: 'guestNewLesson',
        path: 'lessons/new',
        component: NewLessonPage,
      },
      {
        name: 'guestInstructor',
        path: 'instructors/:instructorId',
        props: true,
        component: InstructorPage,
      },
      {
        name: 'guestBookLesson',
        path: 'instructors/:instructorId/book',
        props: true,
        component: BookLessonPage,
      },
      {
        name: 'guestInvitePage',
        path: 'invites/:inviteId',
        props: true,
        component: GuestInvitePage,
      },
    ],
  },
  {
    path: '/my/',
    component: InsideTabsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/my/home',
      },
      {
        name: 'insideHome',
        path: 'home',
        meta: { title: 'Welcome to SwingMatch!' },
        component: HomePage,
      },
      {
        name: 'chats',
        path: 'chats',
        meta: { title: 'Chats' },
        component: ChatsIndex,
      },
      {
        name: 'chat',
        path: 'chats/:chatId',
        props: true,
        component: ChatsShow,
      },
      {
        name: 'schedules',
        path: 'schedules',
        meta: { title: 'Schedules' },
        component: SchedulesPage,
      },
      {
        name: 'account',
        path: 'account',
        meta: { title: 'My Profile' },
        component: AccountPage,
      },
      {
        name: 'lessons',
        path: 'lessons',
        meta: { title: 'My Lessons' },
        component: LessonsPage,
      },
      {
        name: 'lessonPage',
        path: 'lessons/:lessonId',
        props: true,
        component: ShowLessonPage,
      },
      {
        name: 'invitePage',
        path: 'invites/:inviteId',
        props: true,
        component: InvitePage,
      },
      {
        name: 'lessonEditPage',
        path: 'lessons/:lessonId/edit',
        props: true,
        component: EditLessonPage,
      },
      {
        name: 'newLesson',
        path: 'lessons/new',
        meta: { title: 'Find Instructor' },
        component: NewLessonPage,
      },
      {
        name: 'instructor',
        path: 'instructors/:instructorId',
        props: true,
        component: InstructorPage,
        meta: { allowedForGuests: true },
      },
      {
        name: 'bookLesson',
        path: 'instructors/:instructorId/book',
        props: true,
        component: BookLessonPage,
        meta: { allowedForGuests: true },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async function (to) {
  const currentUser = await getCurrentUser()
  if (to.name === 'invitePage' && !currentUser) {
    console.log('Invite page but no current user. Redirecting to invite guest page.')
    return {
      name: 'guestInvitePage', params: { inviteId: to.params.inviteId },
    }
  }
  if (to.meta.requiresAuth && to.meta.allowedForGuests && !currentUser) {
    console.log('User is required but not logged in. The page can be accessed by guests. Redirecting to guest page.')
    return to.fullPath.replace('/my/', '/guest/')
  }
  if (to.meta.requiresAuth && !currentUser) {
    console.log('User is required but not logged in. Redirecting to login page.')
    return {
      name: 'guestHome',
      query: to.fullPath !== '/' ? { redirect: to.fullPath } : {},
    }
  }
  if (to.meta.requiresGuest && currentUser) {
    console.log('Guest is required but user is logged in. Redirecting to user dashboard.')
    return { name: 'insideHome' }
  }
})

export default router
