<template>
  <ion-item
    detail="true" data-testid="lesson-item" :data-lesson-id="lesson.id"
    lines="full"
  >
    <user-avatar slot="start" :url="userAvatarUrl" />
    <ion-label>
      <span v-if="currentUser == 'student'">{{ lesson.instructor.name }}</span>
      <span v-if="currentUser == 'instructor'">{{ lesson.student.name }}</span>
      <p>{{ lesson.formattedDate }}, {{ displayFullLength(lesson.time, lesson.length) }}</p>
      <p>
        <lesson-status :lesson="lesson" />
        <ion-note v-if="lesson.needToCollectPayment()" color="danger">
          {{ lesson.paymentStatus() }}
        </ion-note>
      </p>
      <!-- <p>{{ lesson.location.name }}</p> -->
    </ion-label>
  </ion-item>
</template>

<script setup>
import { IonItem, IonLabel, IonNote } from '@ionic/vue'

import LessonStatus from '@/4_features/LessonStatus.vue'
import UserAvatar from '@/3_widgets/UserAvatar.vue'
import { displayFullLength } from '@/6_shared/utils/formattingLessons'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUser: { type: String, required: true },
})

const userAvatarUrl = props.currentUser === 'student' ? props.lesson.instructor.photoURL : props.lesson.student.photoURL

</script>

<style scoped>
ion-label {
  text-transform: capitalize;
}
</style>
