<template>
  <ion-page data-testid="lesson-page">
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="lesson" data-testid="lesson-status">
          <lesson-status :lesson="lesson" />
        </ion-title>
        <ion-buttons slot="start">
          <ion-button :router-link="{name: 'lessons'}" router-direction="back" data-testid="lesson-back-button">
            <ion-icon :icon="chevronBackOutline" />
            Back
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" fullscreen="true">
      <error-message v-if="errorMsg" :title="errorMsg" />
      <template v-if="lesson">
        <lesson-card :lesson="lesson" :current-user="userStore.is.type" />

        <payment-info :lesson="lesson" :current-user="userStore.is.type" />
        <ion-accordion-group value="">
          <!-- empty value to hide Activity by default -->
          <ion-accordion value="activity">
            <ion-item slot="header" color="light">
              <ion-label>Activity</ion-label>
            </ion-item>
            <div slot="content" class="ion-padding">
              <ion-spinner v-if="lessonEvents.length == 0" />
              <ul data-testid="lesson-events">
                <li v-for="event in lessonEvents" :key="event.id">
                  {{ event.type }} - {{ useDateFormat(event.createdAt*1000, 'MMM D, h:mm a').value }}
                  <ion-note v-if="event.reason">{{ event.reason }}</ion-note>
                  <ion-note v-if="event.changes" data-testid="lesson-event-reschedule" :data-changes="JSON.stringify(event.changes)">new timeslot: {{ event.changes.new.formattedDate }}, {{ event.changes.new.time }}</ion-note>
                  <ion-note v-if="event.review">review: {{ event.review.comment }}, {{ '★'.repeat(event.review.rating) }}</ion-note>
                  <div style="display:none">
                    <span v-if="event.cancellationRequestId" data-testid="cancellation-request-id">{{ event.cancellationRequestId }}</span>
                  </div>
                </li>
                <li v-for="log in (lesson.logs || [])" :key="log">
                  {{ log.type }} - {{ useDateFormat(log.createdAt*1000, 'MMM D, h:mm a').value }}
                </li>
              </ul>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </template>
    </ion-content>
    <lesson-actions
      v-if="lesson"
      :lesson="lesson" :current-user-type="userStore.is.type"
      @lesson:confirm="confirmLesson"
      @lesson:decline="declineLesson($event)"
      @lesson:cancel="cancelLesson"
      @lesson:review="reviewLesson"
      @lesson:complete="completeLesson"
    />
  </ion-page>
</template>

<script setup>
import {
  IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonContent, IonHeader, IonIcon,
  IonItem, IonLabel, IonNote, IonPage, IonSpinner, IonTitle, IonToolbar,
} from '@ionic/vue'
import { chevronBackOutline } from 'ionicons/icons'
import { computed } from 'vue'
import { useCollection } from 'vuefire'
import { useDateFormat } from '@vueuse/core'

import ErrorMessage from '@/3_widgets/ErrorMessage.vue'
import LessonActions from '@/views/golfers/lessons/LessonActions.vue'
import LessonCard from '@/views/golfers/lessons/LessonCard.vue'
import LessonStatus from '@/4_features/LessonStatus.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import PaymentInfo from '@/3_widgets/lessons/PaymentInfo.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  lessonId: { type: String, required: true },
})

const userStore = useUserStore()
const lessonsRepo = new LessonsRepository()

const lesson = lessonsRepo.get(props.lessonId)
const localRef = computed(() => lessonsRepo.getEventsRef(props.lessonId))
const lessonEvents = useCollection(localRef, { ssrKey: 'lesson-events' })
const errorMsg = computed(() => {
  return lesson.error?.value?.code === 'permission-denied'
    ? 'You do not have permission to access this lesson'
    : ''
})

async function confirmLesson() {
  await sendRequest('Confirming Lesson Request...', async () => {
    const result = (await lessonsRepo.confirm(lesson.value)).data
    if (result.status !== 'success') {
      alert(result.error)
    }
  }, lesson.value)
}

async function declineLesson(reason) {
  await sendRequest('Declining Lesson Request...', async () => {
    lesson.value.declineReason = reason
    await lessonsRepo.decline(lesson.value, reason)
  }, lesson.value)
}

async function cancelLesson(cancellationReason) {
  await sendRequest('Cancelling Lesson...', async () => {
    await lessonsRepo.cancelLesson(lesson.value, cancellationReason)
  }, lesson.value)
}

async function reviewLesson(review) {
  await sendRequest('Saving Review...', async () => {
    await lessonsRepo.reviewLesson(lesson.value, review)
  }, lesson.value)
}

async function completeLesson() {
  await sendRequest('Completing Lesson...', async () => {
    await lessonsRepo.completeLesson(lesson.value)
  }, lesson.value)
}
</script>
