<template>
  <ion-footer translucent="true">
    <reschedule-block
      v-if="lesson?.changes" :lesson="lesson" :current-user="currentUserType"
      @changes:approve="answerRescheduleRequest(true)"
      @changes:decline="answerRescheduleRequest(false)"
    />
    <ion-toolbar
      v-if="status.isCompleted && currentUserType != 'instructor'"
      class="ion-text-center ion-padding"
      data-testid="lesson-completion-block"
    >
      <p class="ion-no-margin ion-margin-bottom">
        <ion-note v-if="!lesson.review" color="dark">Leave lesson feedback for instructor:</ion-note>
      </p>
      <div :class="review.rating == 5 ? 'animate__animated animate__tada' : ''">
        <ion-icon
          :icon="review.rating >=1 ? star : starOutline" size="large"
          :color="color(1)" @click="!lesson.review ? review.rating = 1 : true"
        />
        <ion-icon
          :icon="review.rating >=2 ? star : starOutline" size="large"
          :color="color(2)" @click="!lesson.review ? review.rating = 2 : true"
        />
        <ion-icon
          :icon="review.rating >=3 ? star : starOutline" size="large"
          :color="color(3)" @click="!lesson.review ? review.rating = 3 : true"
        />
        <ion-icon
          :icon="review.rating >=4 ? star : starOutline" size="large"
          :color="color(4)" @click="!lesson.review ? review.rating = 4 : true"
        />
        <ion-icon
          :icon="review.rating >=5 ? star : starOutline" size="large"
          :color="color(5)" @click="!lesson.review ? review.rating = 5 : true"
        />
      </div>
      <ion-textarea
        v-if="!lesson.review || lesson.review?.comment" v-model="review.comment"
        auto-grow="true" placeholder="Leave a review... (optional)"
        mode="md" :fill="!lesson.review ? 'outline' : ''"
        :readonly="lesson.review?.comment" rows="1" style="font-style: italic; text-align: left;"
      />
      <ion-button
        v-if="!lesson.review" color="success" :disabled="review.rating == 0"
        data-testid="lesson-review" @click="reviewLesson"
      >
        Save Review
      </ion-button>
    </ion-toolbar>
    <ion-toolbar v-if="status.actionable && !status.needToAnswerRescheduleRequest" data-testid="lesson-actions">
      <ion-grid>
        <ion-row>
          <ion-col v-show="status.canDecline">
            <ion-button
              v-show="!lesson.isRescheduleRequested()" color="danger" fill="outline"
              expand="block" data-testid="lesson-decline" @click="declineLessonAlert"
            >
              <ion-icon slot="start" :icon="close" />
              Decline
            </ion-button>
          </ion-col>
          <ion-col v-show="status.canCancel">
            <ion-button
              id="show-cancellation-modal" data-testid="lesson-cancel" color="danger"
              fill="outline" expand="block"
            >
              <ion-icon slot="start" :icon="ban" />
              Cancel
            </ion-button>
            <!--
            TODO: Maybe for later
            <ion-button
              data-testid="lesson-cancel" color="danger"
              fill="outline" expand="block" @click="cancelLessonAlert"
            >
              <ion-icon slot="start" :icon="ban" />
              Cancel Alert
            </ion-button> -->
          </ion-col>
          <ion-col v-show="status.canReschedule">
            <ion-button
              expand="block" color="primary" data-testid="lesson-reschedule"
              fill="outline" :router-link="{name: 'lessonEditPage', params: {lessonId: lesson.id}}"
            >
              <ion-icon slot="start" :icon="calendarOutline" />
              Reschedule
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col v-show="status.canConfirm">
            <ion-button
              v-show="!lesson.isRescheduleRequested()" data-testid="lesson-confirm"
              expand="block" color="success" fill="solid"
              @click="confirmLessonAlert"
            >
              <ion-icon slot="start" :icon="checkmarkOutline" />
              Confirm
            </ion-button>
          </ion-col>
          <ion-col v-if="status.canComplete">
            <ion-button
              data-testid="complete-lesson" color="success" fill="solid"
              expand="block" @click="emit('lesson:complete')"
            >
              Mark as completed
            </ion-button>
          </ion-col>
          <ion-col v-if="status.canWaitCompletion" class="ion-text-center">
            <ion-note color="dark">
              Pending completion by instructor.
            </ion-note>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-modal
        id="info-modal" ref="cancellationModalRef" trigger="show-cancellation-modal"
        data-testid="cancellation-modal"
      >
        <div class="ion-padding">
          <p>
            <strong>Need to cancel?</strong>
            <br>
            Consider rescheduling instead!
          </p>
          <p v-if="currentUserType == 'student'">
            Connect with your instructor to find a new time. Remember, you get a full refund (minus a ~3% processing fee) with 24+ hours notice. No refund otherwise.
          </p>
          <ion-textarea
            v-model.trim="cancellationReason" fill="outline" mode="md"
            placeholder="Provide reason (required)" rows="1"
            data-testid="lesson-cancel-reason" auto-grow="true"
          />
          <ion-button
            color="danger" fill="solid" expand="block"
            size="default" class="ion-margin-top"
            data-testid="lesson-cancel-confirm" :disabled="!cancellationReason"
            @click="cancelLesson"
          >
            Cancel
          </ion-button>
        </div>
      </ion-modal>
    </ion-toolbar>
  </ion-footer>
</template>

<script setup>
import {
  IonButton, IonCol, IonFooter, IonGrid, IonIcon,
  IonModal, IonNote, IonRow, IonTextarea, IonToolbar, alertController,
} from '@ionic/vue'
import { ban, calendarOutline, checkmarkOutline, close, star, starOutline } from 'ionicons/icons'
import { computed, reactive, ref } from 'vue'
import { formatDate } from 'date-fns'

import RescheduleBlock from '@/views/golfers/lessons/RescheduleBlock.vue'
import { sendRequest } from '@/6_shared/utils/sendRequest'

const props = defineProps({
  lesson: { type: Object, required: true },
  currentUserType: { type: String, required: true },
})
const emit = defineEmits(['lesson:confirm', 'lesson:decline', 'lesson:cancel', 'lesson:review', 'lesson:complete'])

async function confirmLessonAlert() {
  const alert = await alertController.create({
    header: 'Confirm Lesson',
    message: 'Confirm your availability. Golfer will be charged for the lesson and notified.',
    buttons: [
      { text: 'Cancel', role: 'cancel' },
      { text: 'Confirm', role: 'confirm', handler: () => emit('lesson:confirm') },
    ],
  })
  await alert.present()
}

async function declineLessonAlert() {
  const alert = await alertController.create({
    header: 'Decline Lesson',
    message: 'Please provide a reason for declining the lesson request. Golfer will be notified.',
    buttons: [
      { text: 'Cancel', role: 'cancel' },
      { text: 'Decline', role: 'destructive', handler: declineReason => emit('lesson:decline', declineReason[0]) },
    ],
    inputs: [{ type: 'textarea', placeholder: 'optional...' }],
  })
  await alert.present()
}

// TODO: Maybe for later
// async function cancelLessonAlert() {
//   let message = '<p>Need to cancel? Consider <strong>rescheduling</strong> instead!</p>'
//   if (props.currentUserType === 'student') {
//     message += '<p>Connect with your instructor to find a new time. Remember, you get a full refund (minus a ~3% processing fee) with 24+ hours notice. No refund otherwise.</p>'
//   }
//   const alert = await alertController.create({
//     header: 'Cancel Lesson',
//     message: message,
//     buttons: [
//       { text: 'Nevermind', role: 'cancel' },
//       { text: 'Cancel Lesson', role: 'destructive', handler: declineReason => emit('lesson:cancel', declineReason[0]), cssClass: 'ion-color-danger' },
//     ],
//     inputs: [{ type: 'textarea', placeholder: 'optional...' }],
//   })
//   await alert.present()
// }

function lessonDayPassed() {
  return formatDate(new Date(), 'yyyy-MM-dd') >= props.lesson.date
}

const status = computed(() => {
  return {
    waitingConfirmationByInstructor: props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor',
    canReschedule: props.currentUserType === 'instructor' || (props.currentUserType === 'student' && !lessonDayPassed()),
    canDecline: props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor',
    canConfirm: props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor',
    canCancel: (!props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor') || (props.currentUserType === 'student' && !lessonDayPassed()),
    canComplete: !props.lesson.isPendingConfirmation() && props.currentUserType === 'instructor' && lessonDayPassed(),
    canWaitCompletion: !props.lesson.isPendingConfirmation() && props.currentUserType === 'student' && lessonDayPassed(),
    isCompleted: props.lesson.isCompleted(),
    actionable: !props.lesson.isDeclinedByInstructor() && !props.lesson.isCancelled() && !props.lesson.isCompleted(),
    needToAnswerRescheduleRequest: props.lesson.isRescheduleRequested() && props.lesson.changes.requestor.type !== props.currentUserType,
  }
})

const cancellationModalRef = ref()
const cancellationReason = ref('')

function cancelLesson() {
  emit('lesson:cancel', cancellationReason.value)
  cancellationModalRef.value.$el.dismiss()
}

const review = reactive({
  rating: props.lesson.review?.rating || 0,
  comment: props.lesson.review?.comment || '',
})

function reviewLesson() {
  emit('lesson:review', review)
}

function color(starPlace) {
  let ratingColor = 'danger'
  if (review.rating >= 2) ratingColor = 'warning'
  if (review.rating >= 4) ratingColor = 'success'
  return review.rating >= starPlace ? ratingColor : ''
}

import { LessonsRepository } from '@/data/LessonsRepository'
const lessonsRepo = new LessonsRepository()
async function answerRescheduleRequest(approved) {
  const message = approved ? 'Approving' : 'Declining'
  await sendRequest(`${message} Reschedule Request...`, async () => {
    await lessonsRepo.answerRescheduleRequest(props.lesson, approved)
  }, props.lesson)
}

</script>

<style scoped>
ion-modal#info-modal {
  --width: 90%;
  --max-width: 90%;
  --min-width: 250px;
  --min-height: 150px;
  --height: fit-content;
  --max-height: 90%;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
</style>
