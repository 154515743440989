<template>
  <ion-page>
    <ion-fab
      slot="fixed" vertical="bottom" horizontal="end"
      :edge="false"
    >
      <ion-button
        v-if="userStore.is.instructor" fill="solid" color="success"
        @click="createInvite"
      >
        <ion-icon slot="start" :icon="golfOutline" />
        Lesson Invite
      </ion-button>
    </ion-fab>
    <ion-content color="light" fullscreen="false" data-testid="lessons-page">
      <ion-segment
        v-if="lessons.length" v-model="subPage" scrollable="true"
        data-testid="lessons-statuses"
      >
        <template v-for="state in STATES" :key="state">
          <ion-segment-button :value="state">
            <ion-label class="ion-text-capitalize">
              {{ state }}
              <ion-badge v-if="filterLessons(state).length && ['pending', 'upcoming'].includes(state)">{{ filterLessons(state).length }}</ion-badge>
            </ion-label>
          </ion-segment-button>
        </template>
      </ion-segment>
      <template v-if="filterLessons(subPage).length == 0">
        <br>
        <ion-card style="text-align:center">
          <br>
          <ion-icon :icon="warningOutline" style="font-size: 64px;" />
          <ion-card-header>
            <ion-card-title class="ion-text-capitalize">No {{ subPage }} Lessons</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p v-if="userStore.is.student">Click "Book" to book a new lesson.</p>
            <p v-if="userStore.is.instructor">You will be notified when a student books a lesson with you.</p>
          </ion-card-content>
        </ion-card>
      </template>
      <ion-list v-if="filterLessons(subPage).length > 0" class="ion-padding-top">
        <lesson-item
          v-for="lesson in filterLessons(subPage)" :key="lesson.id" :lesson="lesson"
          :current-user="userStore.is.type"
          :router-link="{name: 'lessonPage', params: {lessonId: lesson.id}}"
        />
      </ion-list>
      <p class="ion-padding-horizontal">
        <ion-note>
          <span v-if="subPage == 'pending'">Lessons awaiting confirmation or rescheduling</span>
          <span v-if="subPage == 'upcoming'">Lessons scheduled for a future date</span>
          <span v-if="subPage == 'completed'">Lessons that have been successfully finished</span>
          <span v-if="subPage == 'cancelled'">Lessons that have been either cancelled, or not confirmed by instructor</span>
        </ion-note>
      </p>
      <purchased-packages-block />
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  IonContent, IonFab, IonIcon, IonLabel, IonList, IonNote, IonPage, IonSegment, IonSegmentButton,
} from '@ionic/vue'
import { computed, ref, watchEffect } from 'vue'
import { golfOutline, warningOutline } from 'ionicons/icons'
import { groupBy } from 'lodash'
import { useRouter } from 'vue-router'

import { LessonInvitesRepository } from '@/data/LessonInvite'
import LessonItem from '@/views/golfers/lessons/LessonItem.vue'
import { LessonsRepository } from '@/data/LessonsRepository'
import PurchasedPackagesBlock from '@/3_widgets/packages/PurchasedPackagesBlock.vue'
import { useUserStore } from '@/stores/user'

const STATES = ['pending', 'upcoming', 'completed', 'cancelled']

const userStore = useUserStore()
const router = useRouter()

const lessonsRepo = new LessonsRepository()

const subPage = ref('upcoming')

const lessons = lessonsRepo.getUserLessons()
const lessonsGrouped = computed(() => {
  return groupBy(lessons.value, lesson => lesson.stateGroup())
})

watchEffect(() => {
  if (lessonsGrouped.value) {
    const firstStateWithLessons = STATES.find(state => lessonsGrouped.value[state]?.length > 0)
    subPage.value = firstStateWithLessons || 'upcoming'
  }
})

function filterLessons(state) {
  if (state === 'all') return Object.values(lessonsGrouped.value).flat()
  const lessons = (lessonsGrouped.value[state] || []).sort((lesson1, lesson2) => lesson1.datetime - lesson2.datetime) // sort by date, oldest to newest (most near to now first)
  if (state === 'cancelled' || state === 'completed') lessons.reverse() // cancelled and completed lessons should be most recent first
  return lessons
}

async function createInvite() {
  if (userStore.userData().instructor?.lessonTypes?.length > 0) {
    const lessonInviteRepo = new LessonInvitesRepository()
    const invite = await lessonInviteRepo.createInviteLink()
    router.push({ name: 'invitePage', params: { inviteId: invite.id }})
  } else {
    alert('Please add a schedule first')
  }
}
</script>

<style scoped>
ion-badge {
  font-weight: normal;
  margin-left: -3px;
  font-size: 10px;
}
</style>
